import $ from '../core/Dom';

export default {
    init: () => {
        const openShareWindow = (href, width = 600, height = 600) => {
            const left = (window.screen.width / 2) - (width / 2);
            const top = (window.screen.height / 2) - (height / 2);
            window.open(href, 'shareWindow', `height=${height},width=${width}, top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no`);
        };

        $('[data-share-link]').on('click', e => {
            e.preventDefault();
            openShareWindow(e.triggerTarget.href);
        });
    }
};
