import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default el => {
    let Flickity;
    let flkty;
    let pagedots;
    const dom = $(el);
    const wrapper = dom.find('[data-wrapper]').get(0);

    const initSlider = () => {
        flkty = new Flickity(wrapper, {
            contain: true,
            dragThreshold: 10,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: true,
            wrapAround: false,
            freeScroll: false,
            groupCells: true,
            freeScrollFriction: 0.045,
            on: {
                ready() {
                    pagedots = dom.find('.flickity-page-dots');
                    dom.parent().append(pagedots);
                }
            }
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });
    };

    const loadAndInitSlider = () => {
        if (Flickity) {
            initSlider();
        } else {
            loadFlickity(f => {
                Flickity = f;
                initSlider();
            });
        }
    };

    const destroySlider = () => {
        if (flkty) {
            $(wrapper).append(pagedots);
            flkty.destroy();
            flkty = null;
        }
    };

    const onResize = () => {
        if (flkty && Viewport.width < 750) {
            destroySlider();
        } else if (!flkty && Viewport.width >= 750) {
            loadAndInitSlider();
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        destroySlider();
    };

    return {
        init,
        destroy
    };
};
