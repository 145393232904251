import gsap from 'gsap';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { DICTIONARY_OPEN } from '../lib/events';

export default el => {
    const dom = $(el);
    const body = $('body');
    const popup = $(el.nextElementSibling);
    const close = popup.find('[data-close]');

    let isOpen = false;
    let elementToFocus = null;

    const onKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1 && isOpen) {
            onToggle();
        } else if (['Enter', 13].indexOf(key) > -1) {
            if (document.activeElement === el || document.activeElement === close.get(0)) {
                onToggle();
            }
        }
    };

    const onToggle = () => {
        if (isOpen) {
            isOpen = false;
            elementToFocus = dom;
        } else {
            isOpen = true;
            elementToFocus = close;
            Dispatch.emit(DICTIONARY_OPEN, el);
        }
        popup.attr('hidden', isOpen ? null : true);
        elementToFocus.focus();
    };

    const onMouseDown = e => {
        if (isOpen && e.target !== el && !(e.target === el.nextElementSibling || el.nextElementSibling.contains(e.target))) {
            onToggle();
        }
    };

    const onDictionaryOpen = (key, element) => {
        if (isOpen && element !== el) {
            isOpen = false;
            popup.attr('hidden', true);
        }
    };

    const init = () => {
        dom.on('click', onToggle);
        close.on('click', onToggle);
        body.on('keyup', onKeyUp);
        body.on('mousedown', onMouseDown);
        Dispatch.on(DICTIONARY_OPEN, onDictionaryOpen);
    };

    const destroy = () => {
        dom.off('click', onToggle);
        close.off('click', onToggle);
        body.off('keyup', onKeyUp);
        body.off('mousedown', onMouseDown);
        Dispatch.off(DICTIONARY_OPEN, onDictionaryOpen);
    };

    return {
        init,
        destroy
    };
};
