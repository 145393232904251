import $ from '../core/Dom';

export default el => {
    const button = $(el);
    const target = $(document.getElementById(button.attr('aria-controls')));

    const toggle = () => {
        const isOpen = button.attr('aria-expanded') === 'true';
        button.attr('aria-expanded', isOpen ? 'false' : 'true');
        target.toggleClass('js-is-expanded', !isOpen);
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };

};
