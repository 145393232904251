import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import CustomBounce from 'gsap/CustomBounce';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Components from '../core/Components';

const loadMapboxGl = require('bundle-loader?lazy&name=mapboxgl!../lib/mapbox-gl');

gsap.registerPlugin(CustomEase, CustomBounce);

export default (el, props) => {

    const { markers, token, style } = props;

    const mapEl = $(el).find('[data-map]').get(0);
    const touchPanHelp = $(el).find('[data-help]').get(0);

    let observer;
    let mapboxgl;
    let map;
    let mapMarkers;
    let activePopup;

    let resetPoint = null;
    let prevWidth = Viewport.width;
    let suppressTouchPanHelpText = false;
    let showTouchPanHelpTextTimer = null;

    const hideTouchPanHelpText = () => {
        if (showTouchPanHelpTextTimer) {
            clearTimeout(showTouchPanHelpTextTimer);
            showTouchPanHelpTextTimer = null;
        }
        gsap.set(touchPanHelp, {opacity: 0});
    };

    const showTouchPanHelpText = () => {
        if (suppressTouchPanHelpText) {
            hideTouchPanHelpText();
            return;
        }
        if (showTouchPanHelpTextTimer) {
            clearTimeout(showTouchPanHelpTextTimer);
        }
        showTouchPanHelpTextTimer = setTimeout(() => {
            gsap.set(touchPanHelp, {opacity: 1});
        }, 500);
    };

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    const isTouchEvent = e => e.originalEvent && 'touches' in e.originalEvent;

    const isTwoFingerTouch = e => e.originalEvent.touches.length >= 2;

    const fitMap = () => {
        if (mapMarkers.length === 1) {
            map.setZoom(15);
            map.setCenter(mapMarkers[0].getLngLat());
        } else {
            const bounds = new mapboxgl.LngLatBounds();
            mapMarkers.forEach(marker => {
                bounds.extend(marker.getLngLat());
            });
            map.fitBounds(bounds, {
                duration: 0,
                padding: clamp(Viewport.width * 0.1, 60, 200)
            });
        }
    };

    const createMarkers = () => {
        mapMarkers = markers.map(data => {
            const button = document.createElement('button');
            button.setAttribute('type', 'button');
            button.setAttribute('aria-label', data.label || '');
            button.className = 'map__marker';
            $(button).append('<span><span class="map__marker-icon"></span><span class="map__marker-close"></span><span class="map__marker-dot"></span></span>');
            const marker = new mapboxgl.Marker(button).setLngLat(data.latlng);
            $(button).data('marker', marker);
            const popupOffsetY = 30;
            const popup = new mapboxgl.Popup({
                className: 'map__popup',
                maxWidth: '300px',
                closeButton: false,
                anchor: 'bottom',
                offset: [0, -popupOffsetY]
            }).setHTML(data.popup);
            popup.on('open', () => {
                if (activePopup) {
                    activePopup.remove();
                }
                Components.init(popup._container);
                const center = map.getCenter();
                const point = map.project(popup._lngLat);
                const {clientWidth: popupWidth, clientHeight: popupHeight} = popup._container;
                const {clientWidth: mapWidth, clientHeight: mapHeight} = map._container;
                if (point.y - (popupHeight + 150) < 0 || point.x - (popupWidth + 150) < 0) { // && popupHeight > mapHeight
                    const panToX = mapWidth * 0.5;
                    const panToY = (mapHeight + (popupHeight + popupOffsetY)) * 0.5;
                    map.panBy([point.x - panToX, point.y - panToY], {duration: 500, essential: true});
                }
                requestAnimationFrame(() => {
                    marker.getElement().classList.add('is-active');
                    resetPoint = center;
                    activePopup = popup;
                });
                const inner = $(popup._container).find('[data-inner]').get(0);
                gsap.timeline()
                    .fromTo(popup._container, {opacity: 0}, {opacity: 1, duration: 0.3}, 0)
                    .fromTo(inner.parentNode, {y: 20}, {y: 0, duration: 1, ease: 'Quint.easeOut'}, 0);
            });
            popup.on('close', e => {
                mapMarkers.forEach(marker => {
                    marker.getElement().classList.remove('is-active');
                });
                if (e.target && e.target._container) {
                    Components.destroy(e.target._container);
                }
                activePopup = null;
                if (mapMarkers.length > 1 && resetPoint) {
                    map.panTo(resetPoint);
                }
                resetPoint = null;
            });
            marker.setPopup(popup);
            return marker;
        });
    };

    const onMapDrag = () => {
        resetPoint = null;
    };

    const onMapLoad = () => {
        fitMap();
        CustomBounce.create('markerBounce', {strength: 0.5, squash: 3, squashID: 'markerBounce-squash'});
        const markers = mapMarkers.map(marker => marker.getElement().firstElementChild);
        gsap.timeline()
            .fromTo(el, {opacity: 0}, {opacity: 1, duration: 1}, 0)
            .fromTo(markers, {opacity: 0}, {
                opacity: 1,
                duration: 0.3,
                ease: 'Cubic.easeIn',
                stagger: 0.1
            }, 'markers')
            .fromTo(markers, {y: -200}, {
                y: 0,
                duration: 1,
                ease: 'markerBounce',
                stagger: 0.05
            }, 'markers')
            .to(markers, {
                scaleX: 1.2,
                scaleY: 0.6,
                duration: 1,
                transformOrigin: 'center bottom',
                ease: 'markerBounce-squash',
                stagger: 0.05
            }, 'markers');
    };

    const createMap = () => {
        if (map) {
            return;
        }
        if (!mapboxgl) {
            loadMapboxGl(({default: bundle}) => {
                mapboxgl = bundle;
                createMap();
            });
            return;
        }

        mapboxgl.accessToken = token;

        createMarkers();

        map = new mapboxgl.Map({
            container: mapEl,
            style,
            center: mapMarkers[0].getLngLat(),
            logoPosition: 'bottom-left',
            scrollZoom: false
        });

        const isTouch = $('html').hasClass('touch');
        if (!isTouch) {
            const nav = new mapboxgl.NavigationControl({
                showZoom: true,
                showCompass: false
            });
            map.addControl(nav, 'top-right');
        } else {
            map.dragPan.disable();
        }

        suppressTouchPanHelpText = !isTouch;

        map.doubleClickZoom.disable();

        mapMarkers.forEach(marker => {
            marker.addTo(map);
        });

        map.on('drag', onMapDrag);
        map.on('load', onMapLoad);

        map.on('dragstart', e => {
            if (!isTouchEvent(e)) {
                return;
            }
            if (isTwoFingerTouch(e)) {
                hideTouchPanHelpText();
                map.dragPan.enable();
            } else {
                map.dragPan.disable();
            }
        });

        // Drag events not emited after dragPan disabled, so I use touch event here
        map.on('touchstart', event => {
            if (isTouchEvent(event) && isTwoFingerTouch(event)) {
                map.dragPan.enable();
            }
            showTouchPanHelpText();
        });

        map.on('touchend', e => {
            if (isTouchEvent(e)) {
                map.dragPan.disable();
            }
            hideTouchPanHelpText();
        });

    };

    const onObserve = entries => {
        const {isIntersecting} = entries[0];
        if (isIntersecting) {
            createMap();
        }
    };

    const onResize = () => {
        const {width} = Viewport;
        if (prevWidth && Math.abs(width - prevWidth) < 10) {
            return;
        }
        prevWidth = width;
        if (map) {
            requestAnimationFrame(() => {
                fitMap();
                resetPoint = map.getCenter();
            });
        }
    };

    const init = () => {
        observer = new IntersectionObserver(onObserve);
        observer.observe(el);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        observer.disconnect();
        observer = null;
        if (map) {
            mapMarkers.forEach(marker => {
                marker.remove();
            });
            map.off('drag', onMapDrag);
            map.off('load', onMapLoad);
            map.remove();
            map = null;
        }
        hideTouchPanHelpText();
        Viewport.off('resize', onResize);
        $(el).off('click');
    };

    return {
        init,
        destroy
    }

};
