import gsap from 'gsap';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const dropdown = dom.find('[data-dropdown]');
    const list = dom.find('[data-list]').get(0);

    let collapsed = Viewport.width < 750;

    const resize = () => {
        const rect = list.getBoundingClientRect();
        const shouldCollapse = Viewport.width < 750 || (rect.top + rect.height) > window.innerHeight;
        if (shouldCollapse && !collapsed) {
            console.log('COLLAPSE');
            collapsed = true;
            dropdown.css('display', 'block');
            $(list).addClass('invisible');
        } else if (collapsed && !shouldCollapse) {
            console.log('EXPAND');
            collapsed = false;
            dropdown.css('display', 'none');
            $(list).removeClass('invisible');
        }
    };

    const init = () => {
        Viewport.on('resize', resize);
        resize();
    };

    const destroy = () => {
        Viewport.off('resize', resize);
    };

    return {
        init,
        destroy
    };
};
