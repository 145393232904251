import $ from '../../core/Dom';
import Dispatch from '../../core/Dispatch';
import * as Events from '../../lib/events';

export default el => {
    const form = $(el);

    const onSubmit = e => {
        e.preventDefault();
        Dispatch.emit(Events.SEASONBOOK_TWO_STEP_MANUAL_SUBMIT);
    };

    const init = () => {
        form.on('submit', onSubmit);
    };

    const destroy = () => {
        form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };

};
