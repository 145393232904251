import $ from '../core/Dom';

const loadLottie = require('bundle-loader?lazy&name=[name]!lottie-web');

export default (el, props) => {
    const dom = $(el);

    const logo = dom.find('.page-header__logo');

    let element = null;
    let observer = null;
    let animation = null;
    let inited = false;

    const initAnimation = () => {
        inited = true;
        loadLottie(lottie => {
            animation = lottie.loadAnimation({
                container: element,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/assets/static/logo-anim2.json'
            });
            animation.addEventListener('DOMLoaded', () => {
                logo.addClass('playing');
            });
        });
    };

    const onIntersectionChange = entries => {
        if (!inited) {
            if (entries.filter(entry => entry.isIntersecting).length) {
                initAnimation();
            }
        } else if (animation) {
            if (entries.filter(entry => entry.isIntersecting).length) {
                animation.play();
            } else {
                animation.pause();
            }
        }
    };

    const init = () => {
        dom.append('<div class="page-header__logo-anim" data-lottie></div>');
        element = dom.find('[data-lottie]').get(0);
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
    };

    const destroy = () => {
        observer.unobserve(el);
    };

    return {
        init,
        destroy
    };
};
