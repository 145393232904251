import Alpine from 'alpinejs';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import AudioPlayer from './components/AudioPlayer';
import SharingLinks from './lib/SharingLinks';
import AnchorLinks from './lib/AnchorLinks';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();
    SharingLinks.init();
    AnchorLinks.init();
    window.Alpine = Alpine;

    Alpine.data('audioplayer', AudioPlayer);
    Alpine.start();

    // Release the tabbing again because loaded forms from Markethype :-/
    setTimeout(() => {
        Viewport.releaseTabbing();
    }, 750);
};

require('doc-ready')(init);
