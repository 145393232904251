import $ from '../core/Dom';

export default el => {

    const links = $(el).find('[data-naxos-popup]');

    const openShareWindow = (href, width = 600, height = 600) => {
        const left = (screen.width/2) - (width/2);
        const top = (screen.height/2) - (height/2);
        window.open (href, 'shareWindow', 'height=' + height + ',width=' + width + ', top='+top+',left='+left+',toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
    };

    const onClick = e => {
        e.preventDefault();
        openShareWindow(e.currentTarget.href);
    };

    const destroy = () => {
        links.off('click', onClick);
    };

    const init = () => {
        links.on('click', onClick);
    };

    return {
        init,
        destroy
    };

};
