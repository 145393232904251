import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const dom = $(el);
    const dismiss = dom.find('[data-dismiss]');
    const rootStyle = document.documentElement.style;
    const key = dom.data('key') || 'strip-banner-dismissed';

    let lastBannerHeight = 0;

    const onDismiss = () => {
        const proxy = { height: lastBannerHeight };

        window.vrsg.setCookie(key, 1, 365);
        gsap.to(el, { duration: 1, y: '-100%', ease: 'expo.inOut' });
        gsap.to(proxy, {
            duration: 1,
            height: 0,
            ease: 'expo.inOut',
            onUpdate: () => {
                rootStyle.setProperty('--strip-banner-height', `${proxy.height}px`);
            },
            onComplete: () => {
                destroy();
                el.remove();
                rootStyle.setProperty('--strip-banner-height', '0px');
            }
        });

    };

    const onResize = () => {
        const currentBannerHeight = el.getBoundingClientRect().height;
        if (currentBannerHeight !== lastBannerHeight) {
            lastBannerHeight = currentBannerHeight;
            rootStyle.setProperty('--strip-banner-height', `${currentBannerHeight}px`);
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        dismiss.on('click', onDismiss);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        dismiss.off('click', onDismiss);
    };

    return {
        init,
        destroy
    };

};
