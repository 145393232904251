import gsap from 'gsap';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';

export default el => {

    const dom = $(el);

    const intro = dom.find('[data-intro]').get(0);
    const introHeading = dom.find('[data-intro-heading]').get(0);

    const steps = dom.find('[data-steps]').get(0);
    const stepsIndex = dom.find('[data-steps-index]');

    const player = dom.find('[data-player]');
    const playerAudio = dom.find('[data-player-audio]');
    const playerProgress = dom.find('[data-player-progress]').get(0);
    const playerButton = dom.find('[data-player-button]');
    const playerButtonPlay = playerButton.find('svg').eq(0);
    const playerButtonPause = playerButton.find('svg').eq(1);

    const audio = playerAudio.get(0);

    const samples = dom.find('[data-sample]');
    const samplesWrapper = dom.find('[data-samples-wrapper]');

    const ratingInputs = dom.find('[data-rating-input]');
    const traitsInputs = dom.find('[data-traits-input]');

    const buttonStart = dom.find('[data-start]');
    const buttonNext = dom.find('[data-next]');
    const buttonSubmit = dom.find('[data-submit]');

    let index = 0;
    let updateId = 0;
    let status = 'complete';

    const play = () => {
        audio.play();
    };

    const pause = () => {
        audio.pause();
    };

    const scrub = e => {
        const percent = e.offsetX / player.width();
        audio.pause();
        audio.currentTime = audio.duration * percent;
        audio.play();
    };

    const updateProgress = () => {
        gsap.to(playerProgress, {
            duration: 0.1,
            scaleX: audio.currentTime / audio.duration,
            ease: 'none'
        });
    };

    const stopUpdateInterval = () => {
        if (updateId) {
            clearInterval(updateId);
            updateId = 0;
        }
    };

    const startUpdateInterval = () => {
        stopUpdateInterval();
        updateId = setInterval(updateProgress, 75);
    };

    const updateGUI = () => {
        if (status === 'playing') {
            playerButtonPlay.attr('hidden', '');
            playerButtonPause.attr('hidden', null);
            playerButton.attr('aria-label', playerButton.data('player-pause'));
        } else {
            playerButtonPlay.attr('hidden', null);
            playerButtonPause.attr('hidden', '');
            playerButton.attr('aria-label', playerButton.data('player-play'));
        }
    };

    const onPlay = () => {
        status = 'playing';
        updateGUI();
        startUpdateInterval();
    };

    const onPause = () => {
        status = 'paused';
        updateGUI();
        stopUpdateInterval();
    };

    const onComplete = () => {
        status = 'complete';
        updateGUI();
        stopUpdateInterval();
        gsap.to(playerProgress, { duration: 1.5, scaleX: 0, ease: 'Circ.easeInOut' });
    };

    const onToggleState = e => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (status === 'playing') {
            pause();
        } else {
            play();
        }
    };

    const toggleButton = (button, show) => {
        if (show) {
            gsap.to(button, {
                autoAlpha: 1,
                duration: 0.35,
                ease: 'power1.out'
            });
        } else {
            gsap.to(button, {
                autoAlpha: 0,
                duration: 0.15,
                ease: 'power1.out'
            });
        }
    };

    const toggleNextButton = show => {
        if (show && (index === samples.length - 1)) {
            toggleButton(buttonSubmit.get(0), true);
        } else {
            toggleButton(buttonNext.get(0), show);
        }
    };

    const loadSample = () => {
        stopUpdateInterval();
        gsap.to(playerProgress, {
            duration: status === 'complete' ? 0.1 : 1,
            scaleX: 0,
            ease: 'Circ.easeInOut',
            onStart: () => {
                status = 'complete';
            },
            onComplete: () => {
                audio.setAttribute('src', samples.eq(index).data('sample'));
            }
        });
    };

    const onStart = () => {
        gsap.to([intro, introHeading], {
            autoAlpha: 0,
            duration: 0.35,
            ease: 'power1.out',
            onComplete: () => {
                gsap.set([intro, introHeading], { display: 'none' });
            }
        });
        gsap.to([steps, samplesWrapper.get(0)], {
            opacity: 1,
            duration: 0.35,
            delay: 0.35,
            ease: 'power1.out',
            startAt: { opacity: 0 },
            onStart: () => {
                steps.removeAttribute('hidden');
                samplesWrapper.attr('hidden', null);
            },
            onComplete: loadSample
        });
        player.on('click', scrub);
        player.addClass('js-started');
        toggleButton(buttonStart.get(0), false);
        toggleButton(playerButton.get(0), true);
    };

    const toggleTraits = (traits, show) => {
        const useHeight = $(traits).css('position') !== 'absolute';
        if (show) {
            if (useHeight) {
                gsap.set(traits, { height: 'auto', opacity: 1, visibility: 'visible' });
                gsap.from(traits, {
                    height: 0,
                    duration: 0.5,
                    ease: 'power2.inOut',
                    onComplete: () => {
                        gsap.set(traits, { height: 'auto' });
                    }
                });
            } else {
                gsap.to(traits, {
                    autoAlpha: 1,
                    duration: 0.35,
                    ease: 'power1.out'
                });
            }
        } else {
            if (useHeight) {
                gsap.to(traits, { height: 0, duration: 0.5, ease: 'power2.inOut', clearProps: 'height' });
            } else {
                gsap.to(traits, {
                    autoAlpha: 0,
                    duration: 0.15,
                    ease: 'power1.out'
                });
            }
        }
    };

    const onNext = () => {
        index += 1;
        const current = samples.get(index - 1);
        const next = samples.get(index);

        toggleNextButton(false);
        if (next) {
            gsap.to(current, {
                opacity: 0,
                duration: 0.45,
                ease: 'power2.inOut',
                onStart: () => {
                    toggleTraits($(current).find('[data-rating]').next().get(0), false);
                }
            });
            gsap.to(next, {
                opacity: 1,
                duration: 0.45,
                delay: 0.5,
                ease: 'power2.inOut',
                startAt: { opacity: 0 },
                onStart: () => {
                    stepsIndex.text(index + 1);
                    current.setAttribute('hidden', '');
                    next.removeAttribute('hidden');
                    loadSample();
                }
            });
        }

    };

    const onRating = e => {
        const rating = e.target.value;
        const traits = $(e.target).parent('[data-rating]').next().get(0);
        if (rating > 3) {
            toggleNextButton(false);
            toggleTraits(traits, true);
        } else {
            toggleNextButton(true);
            toggleTraits(traits, false);
        }
    };

    const onTraits = e => {
        const selected = $(e.target).parent('[data-traits]').find('input:checked');
        if (selected.length) {
            toggleNextButton(true);
        } else {
            toggleNextButton(false);
        }
    };

    const init = () => {
        playerAudio.on('playing', onPlay);
        playerAudio.on('pause', onPause);
        playerAudio.on('ended', onComplete);
        buttonStart.on('click', onStart);
        buttonNext.on('click', onNext);
        ratingInputs.on('change', onRating);
        traitsInputs.on('change', onTraits);
        playerButton.on('click', onToggleState);
    };

    const destroy = () => {
        stopUpdateInterval();
        playerAudio.off('playing', onPlay);
        playerAudio.off('pause', onPause);
        playerAudio.off('ended', onComplete);
        buttonStart.off('click', onStart);
        buttonNext.off('click', onNext);
        ratingInputs.off('change', onRating);
        traitsInputs.off('change', onTraits);
        player.off('click', scrub);
        playerButton.off('click', onToggleState);
    };

    return {
        init,
        destroy
    };

};
