/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';
export const AUDIO_ADD = 'AUDIO_ADD';
export const VIDEO_OPEN = 'VIDEO_OPEN';
export const VIDEO_CLOSE = 'VIDEO_CLOSE';
export const REMINDER_OPEN = 'REMINDER_OPEN';
export const REMINDER_CLOSE = 'REMINDER_CLOSE';

export const DICTIONARY_OPEN = 'DICTIONARY_OPEN';
export const SEASONBOOK_LOAD_CONCERTS = 'SEASONBOOK_LOAD_CONCERTS';
export const SEASONBOOK_TWO_STEP_MANUAL_SUBMIT = 'SEASONBOOK_MOBILE_FILTER_SUBMIT';
