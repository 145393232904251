import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const content = $(el).find('[data-content]').get(0);
    let button = $(el).find('[aria-expanded]');
    let chevron = $(el).find('[aria-expanded] svg').get(0);
    let expanded = false;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (expanded && key === 27) {
            close();
        }
    };

    const clickHandler = e => {
        if (!el.contains(e.target)) {
            close();
        }
    };

    const open = () => {
        if (!expanded) {
            expanded = true;
            button.attr('aria-expanded', 'true');
            gsap.set(content, { height: 'auto' });
            gsap.from(content, {
                height: 0,
                duration: 0.25,
                ease: 'power2.inOut',
                onComplete: () => {
                    gsap.set(content, { height: 'auto' });
                }
            });
            gsap.to(chevron, { scaleY: -1, duration: 0.25, ease: 'power2.inOut' });
            document.addEventListener('keyup', keyHandler);
            document.addEventListener('click', clickHandler);
        }
    };

    const close = () => {
        if (expanded) {
            expanded = false;
            button.attr('aria-expanded', 'false');
            gsap.to(content, { height: 0, duration: 0.25, ease: 'power2.inOut', clearProps: 'height' });
            gsap.to(chevron, { scaleY: 1, duration: 0.25, ease: 'power2.inOut' });
            document.removeEventListener('keyup', keyHandler);
            document.removeEventListener('click', clickHandler);
        }
    };

    const toggle = () => {
        if (expanded) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
