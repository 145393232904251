import gsap from 'gsap';
import $ from '../../core/Dom';

export default el => {
    const dom = $(el);

    const DEFAULT_DURATION = 10;
    const buttons = dom.find('a');
    const slides = dom.find('[data-slide]').nodes;
    const numberOfSlides = slides.length - 1;
    const bars = dom.find('[data-progress]');
    const barsWrapper = dom.find('[data-bars-wrapper]').get(0);
    const nextButton = dom.find('[data-next]');
    const prevButton = dom.find('[data-prev]');
    const barsButtons = dom.find('[data-bars-wrapper] button');

    let index = 0;
    let timeline;
    let observer = null;
    let seeking = false;

    const play = () => {
        timeline.play();
        gsap.to(barsWrapper, { opacity: 1, scaleX: 1, scaleY: 1, duration: 0.4, ease: 'power1.inOut' });
    };

    const pause = () => {
        timeline.pause();
        gsap.to(barsWrapper, { opacity: 0, scaleX: 1.05, scaleY: 0.2, duration: 0.35, ease: 'power1.out' });
    };

    const redraw = () => {
        slides.forEach((slide, i) => {
            const classesToAdd = `placed-image z-${(slides.length - 1) - i}${(i !== 0 ? ' hidden' : '')}`;
            slide.removeAttribute('class');
            $(slide).addClass(classesToAdd);
        });
    };

    const updateIndex = value => {
        index = value;
        if (index > numberOfSlides) {
            index = 0;
        } else if (index < 0) {
            index = numberOfSlides;
        }
        seeking = true;
        const progress = (index / (numberOfSlides + 1) + 0.01);
        timeline.progress(progress);
        setTimeout(() => {
            seeking = false;
        }, 100);
        return index;
    };

    const next = () => {
        updateIndex(index + 1);
        slides.push(slides.shift());
        redraw();
    };

    const previous = () => {
        updateIndex(index - 1);
        slides.unshift(...slides.splice(-1));
        redraw();
    };

    const jumpTo = e => {
        const oldIndex = index;
        const newIndex = barsButtons.nodes.indexOf(e.target);
        if (oldIndex !== newIndex) {
            index = updateIndex(newIndex);
            const diff = Math.max(oldIndex, index) - Math.min(oldIndex, index);
            if (index > oldIndex) {
                slides.push(...slides.splice(0, diff));
            } else {
                slides.unshift(...slides.splice(diff * -1));
            }
            redraw();
        }
    };

    const onComplete = () => {
        timeline.restart();
        next();
    };

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            play();
        } else {
            pause();
        }
    };

    const initTimeline = () => {
        timeline = gsap.timeline({ paused: true });

        slides.forEach((slide, i) => {
            const isLastSlide = i === slides.length - 1;
            const duration = DEFAULT_DURATION;
            timeline.to(bars.get(i), {
                scaleX: 1,
                duration,
                ease: 'linear',
                onComplete: () => {
                    if (!seeking) {
                        if (isLastSlide) {
                            onComplete();
                        } else {
                            next();
                        }
                    }
                }
            });
        });
    };

    const init = () => {
        initTimeline();
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
        buttons.on('mouseover', pause);
        buttons.on('mouseout', play);
        nextButton.on('click', next);
        prevButton.on('click', previous);
        barsButtons.on('click', jumpTo);
    };

    const destroy = () => {
        observer.unobserve(el);
        buttons.off('mouseover', pause);
        buttons.off('mouseout', play);
        nextButton.off('click', next);
        prevButton.off('click', previous);
        barsButtons.off('click', jumpTo);
    };

    return {
        init,
        destroy
    };
};
