import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const button = $(el);
    const list = $(`#${button.attr('aria-controls')}`);
    const chevron = $(el).find('[data-chevron]').get(0);
    let expanded = false;

    const open = () => {
        if (!expanded) {
            expanded = true;
            button.attr('aria-expanded', 'true');
            list.css('height', 'auto');
            list.css('marginBottom', '-12px');
            gsap.set(chevron, { scaleY: -1 });
        }
    };

    const close = () => {
        if (expanded) {
            expanded = false;
            button.attr('aria-expanded', 'false');
            list.css('height', null);
            list.css('marginBottom', null);
            gsap.set(chevron, { scaleY: 1 });
        }
    };

    const toggle = () => {
        if (expanded) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
