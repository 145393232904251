import $ from '../core/Dom';

export default (el, props) => {
    const button = $(el);

    const toggle = () => {
        const isOpen = button.attr('aria-expanded') === 'true';
        button.attr('aria-expanded', isOpen ? 'false' : 'true');
        if (!isOpen && props.closeExpandedOnOpen) {
            button.parent().find('[aria-expanded]').not(el).attr('aria-expanded', 'false');
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };

};
