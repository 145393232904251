import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const close = dom.find('[data-close]');
    const wrapper = dom.find('[data-wrapper]');
    const box = wrapper.get(0);

    let active = false;
    let opener = null;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.VIDEO_CLOSE);
        }
    };

    const onOpen = (key, data) => {
        if (!active) {
            active = true;
            opener = document.activeElement;
            document.addEventListener('keyup', keyHandler);
            gsap.set(box, {opacity: 0, scale: 0.7});

            gsap.to(el, { duration: 0.35, autoAlpha: 1, ease: 'power1.inOut' });
            gsap.to(box, { duration: 0.35, delay: 0.35, opacity: 1, ease: 'sine.out' });
            gsap.to(box, { duration: 0.7, delay: 0.35, scale: 1, ease: 'power2.out', onComplete() {
                    wrapper.html(data);
                }});

            setTimeout(() => {
                Viewport.lockTabbing(dom, close);
            }, 10);
        }
    };

    const onClose = () => {
        if (active) {
            active = false;
            document.removeEventListener('keyup', keyHandler);
            gsap.to(box, {duration: 0.5, opacity: 0, scale: 0.85, ease: 'power2.inOut'});
            gsap.to(el, {
                duration: 0.35,
                delay: 0.25,
                autoAlpha: 0,
                ease: 'power1.inOut',
                onComplete() {
                    wrapper.empty();
                }
            });
            Viewport.releaseTabbing(opener);
        }
    };

    const onClick = e => {
        e.preventDefault();
        const target = $(e.triggerTarget);
        wrapper.css('paddingBottom', target.data('ratio'));
        Dispatch.emit(Events.VIDEO_OPEN, target.data('embed'));
    };

    const init = () => {
        Dispatch.on(Events.VIDEO_OPEN, onOpen);
        Dispatch.on(Events.VIDEO_CLOSE, onClose);

        dom.on('click', e => {
            Dispatch.emit(Events.VIDEO_CLOSE);
        });

        $('[data-embed]').on('click', onClick);
    };

    const destroy = () => {
        Dispatch.off(Events.VIDEO_OPEN, onOpen);
        Dispatch.off(Events.VIDEO_CLOSE, onClose);
        dom.off('click');
        $('[data-embed]').off('click', onClick);
    };

    return {
        init,
        destroy
    };
};
