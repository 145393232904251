import gsap from 'gsap';
import $ from '../../core/Dom';

export default (el, props) => {

    const field = $(el).find('[data-search]');
    const wrapper = $(el).find('[data-search-wrapper]');
    const results = $(el).find('[data-search-results]');
    const resultsDefault = results.html();
    let expanded = false;
    let lastValue = '';

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (expanded && key === 27) {
            close();
        }
    };

    const open = () => {
        if (!expanded) {
            results.attr('hidden', null);
            field.val(lastValue);
            expanded = true;
            document.addEventListener('keyup', keyHandler);
        }
    };

    const close = () => {
        if (expanded) {
            results.attr('hidden', '');
            field.val('');
            expanded = false;
            document.removeEventListener('keyup', keyHandler);
        }
    };

    const focusOut = e => {
        if (!wrapper.get(0).contains(e.relatedTarget)) {
            close();
        }
    };

    const search = () => {
        const currentValue = field.val().trim().toLowerCase();
        if (currentValue === '') {
            results.html(resultsDefault);
        } else if (currentValue !== lastValue) {
            results.empty();
            const matches = props.filter(item => item.title.toLowerCase().startsWith(currentValue));
            const response = matches.map(item => `<li><a href="${item.url}" data-search-result>${item.title}</a></li>`).join('');
            results.html(response);
        }
        lastValue = currentValue;
    };

    const init = () => {
        field.on('focus', open);
        wrapper.on('focusout', focusOut);
        field.on('keyup', search);
    };

    const destroy = () => {
        field.off('focus', open);
        wrapper.off('focusout', focusOut);
        field.off('keyup', search);
    };

    return {
        init,
        destroy
    };

};
