import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const dom = $(el);
    const buttons = dom.find('button[data-level]');
    const rows = dom.find('tr[data-level]');
    const groups = dom.find('.floorplan g[id]');

    const select = e => {
        const button = $(e.triggerTarget);
        const level = button.data('level');
        const row = dom.find(`tr[data-level="${level}"]`);
        const group = dom.find(`.floorplan g[id="level_${level}"]`);
        buttons.attr('aria-selected', null);
        button.attr('aria-selected', 'true');
        rows.removeClass('font-bold');
        row.addClass('font-bold');
        groups.attr('aria-selected', null);
        group.attr('aria-selected', 'true');
    };

    const init = () => {
        buttons.on('click', select);
    };

    const destroy = () => {
        buttons.off('click', select);
    };

    return {
        init,
        destroy
    };
};
