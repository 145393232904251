import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../../core/Dom';
import Dispatch from '../../core/Dispatch';
import * as Events from '../../lib/events';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default (el, props) => {

    const button = $(el).find('[data-button]');
    const wrapper = $(el).find('[data-wrapper]');
    const form = $(el).find('form');
    const parent = el.parentElement;

    let scInstance = null;
    let isOpen = button.attr('aria-expanded') === 'true';
    let state = serialize(form.get(0));

    const isUpdated = () => serialize(form.get(0)) !== state;

    const setup = ScrollTrigger => {
        scInstance = ScrollTrigger.create({
            trigger: parent,
            start: 'top top',
            end: 'bottom bottom',
            onEnter() {
                gsap.set(el, { position: 'fixed' });
            },
            onEnterBack() {
                gsap.set(el, { position: 'fixed', top: null });
            },
            onLeave() {
                gsap.set(el, { position: 'absolute', top: 'calc(100% - 79px)' });
            },
            onLeaveBack() {
                gsap.set(el, { position: 'absolute' });
            }
        });
    };

    const onFocusIn = e => {
        if (!el.contains(e.target)) {
            close();
        }
    };

    const onEatFocusIn = e => {
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        button.attr('aria-expanded', 'true');
        if (isUpdated()) {
            button.text(props.apply);
        } else {
            button.text(props.close);
        }
        document.body.addEventListener('focusin', onFocusIn);
    };

    const close = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        button.attr('aria-expanded', 'false');
        button.text(props.default);
        document.body.removeEventListener('focusin', onFocusIn);
    };

    const submit = () => {
        state = serialize(form.get(0));
        Dispatch.emit(Events.SEASONBOOK_TWO_STEP_MANUAL_SUBMIT);
    };

    const toggle = () => {
        if (isOpen) {
            if (isUpdated()) {
                submit();
                close();
            } else {
                close();
            }
        } else {
            open();
        }
    };

    const onChange = e => {
        if (isUpdated()) {
            button.text(props.apply);
        } else {
            button.text(props.close);
        }
    };

    const init = () => {
        button.on('click', toggle);
        wrapper.on('focusin', onEatFocusIn);
        form.on('change', onChange);
        loadScrollTrigger(module => {
            gsap.registerPlugin(module.ScrollTrigger);
            setup(module.ScrollTrigger);
        });
    };

    const destroy = () => {
        button.off('click', toggle);
        wrapper.off('focusin', onEatFocusIn);
        form.off('change', onChange);
        if (scInstance) {
            scInstance.kill();
        }
    };

    return {
        init,
        destroy
    };

};
