import * as eventKeys from '../lib/events';
import Dispatch from '../core/Dispatch';

export default props => ({
    playlist: [],
    playlistOpen: false,
    playing: false,
    paused: true,
    stopped: true,
    index: 0,
    progress: 0,
    open: false,
    currentTrack: {
        id: 'none',
        title: '',
        url: ''
    },

    init() {
        Dispatch.on(eventKeys.AUDIO_ADD, (key, data) => {
            this.addToPlaylist(data);
            if (this.playlist.length === 1) {
                this.setCurrentTrack();
            } else {
                this.play();
            }
            this.open = true;
        });

        this.$refs.audio.addEventListener('timeupdate', e => {
            this.progress = (this.$refs.audio.currentTime / this.$refs.audio.duration) * 100;
        });

        this.$refs.audio.addEventListener('ended', e => {
            this.playing = false;
            if (this.hasNext()) {
                this.next();
            } else {
                // animate progress back
            }
        });

        this.$refs.audio.addEventListener('play', e => {
            console.log('event play');
            this.playing = true;
        });

        this.$refs.audio.addEventListener('pause', e => {
            console.log('event pause');
            this.playing = false;
        });

        this.$refs.progressbar.addEventListener('change', e => {
            console.log('event change');
            this.$refs.audio.currentTime = this.$refs.audio.duration * (this.$refs.progressbar.value / 100);
        });

        window.addEventListener('beforeunload', e => {
            if (this.open && this.playlist.length) {
                const state = JSON.stringify({
                    playlist: this.playlist,
                    index: this.index,
                    playing: this.playing,
                    playlistOpen: this.playlistOpen,
                    progress: this.progress
                });
                window.vrsg.setCookie('audio-player', state);
            } else {
                window.vrsg.setCookie('audio-player', '');
            }
        });

        if (props) {
            this.open = true;
            this.playlist = props.playlist || [];
            this.index = props.index || 0;
            this.playing = props.playing || false;
            this.playlistOpen = props.playlistOpen || false;
            this.progress = props.progress || 0;
            this.setCurrentTrack();
            this.$refs.audio.addEventListener('loadedmetadata', e => {
                this.$refs.audio.currentTime = this.$refs.audio.duration * (this.progress / 100);
            });
        }
    },

    close() {
        this.$refs.audio.pause();
        this.playlist = [];
        this.open = false;
        this.playlistOpen = false;
        this.playing = false;
        this.paused = true;
        this.stopped = true;
        this.progress = 0;
        this.index = 0;
        this.currentTrack = {
            id: 'none',
            title: 'Initial',
            url: ''
        };
    },

    togglePlayList() {
        this.playlistOpen = !this.playlistOpen;
    },

    addToPlaylist(track) {
        this.playlist.push(track);
    },

    removeFromPlaylist(id) {
        this.playlist.splice(this.playlist.findIndex(object => object.id === id), 1);
        if (!this.hasMultipleTracks()) {
            this.playlistOpen = false;
        }
    },

    isCurrentTrack(id) {
        return id === this.currentTrack.id;
    },

    hasMultipleTracks() {
        return this.playlist.length > 1;
    },

    setCurrentTrack() {
        this.currentTrack = this.playlist[this.index];
    },

    hasNext() {
        return this.index < this.playlist.length - 1;
    },

    select(id) {
        const clickedIndex = this.playlist.findIndex(object => object.id === id);
        if (this.index !== clickedIndex) {
            this.index = clickedIndex;
            this.setCurrentTrack();
        }
    },

    next() {
        this.index += 1;
        this.setCurrentTrack();
    },

    hasPrev() {
        return this.index > 0;
    },

    prev() {
        this.index -= 1;
        this.setCurrentTrack();
    },

    play() {
        this.$refs.audio.play();
    },

    pause() {
        this.$refs.audio.pause();
    }
});
