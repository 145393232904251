import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import agent from '../core/request';

export default (el, props) => {

    const dom = $(el);
    const form = dom.find('form');
    // const button = dom.find('[data-submit]');
    const messageSuccess = dom.find('[data-success]');
    const messageError = dom.find('[data-error]');

    const displayErrors = (errors = {}) => {
        form.find('[data-errors]').each(node => {
            const name = $(node).data('errors');
            const parent = $(node).parent('.form-field');
            const errorTarget = parent.find('[data-error-target]');
            const inputs = parent.find('input, select, textarea');
            if (errors.hasOwnProperty(name)) {
                $(node).html([].concat(errors[name]).join('<br/>')).attr('hidden', null);
                errorTarget.addClass('error');
                inputs.on('change keydown', e => {
                    $(e.target).off('change keydown');
                    errorTarget.removeClass('error');
                    $(node).html('').attr('hidden', '');
                    messageError.attr('hidden', '');
                });
            } else {
                $(node).html('').attr('hidden', '');
                errorTarget.removeClass('error');
                inputs.off('change keydown');
            }
        });
        const firstError = form.find('[data-errors]:not([hidden])').get(0);
        if (firstError) {
            const offset = $(firstError.parentNode.parentNode).position().top;
            gsap.to(window, { duration: 0.75, scrollTo: { y: offset, autoKill: false }, ease: 'power2.Out', onComplete: () => {
                const input = $(firstError).parent('.form-field').find('input, select, textarea').get(0);
                if (input) {
                    input.focus();
                }
            }});
        }
    };

    const onSubmit = e => {
        e.preventDefault();
        const params = serialize(e.target);
        agent
            .post('/')
            .accept('application/json')
            .send(params)
            .end((error, res) => {
                const response = JSON.parse(res.text);
                if (error || !response.success) {
                    displayErrors(response.errors);
                    messageError.attr('hidden', null);
                    messageSuccess.attr('hidden', '');
                } else {
                    displayErrors();
                    form.get(0).reset();
                    messageError.attr('hidden', '');
                    messageSuccess.attr('hidden', null);
                }
            });
    };

    const destroy = () => {
        form.off('click', onSubmit);
    };

    const init = () => {
        form.on('submit', onSubmit);
        form.attr('novalidate', '');
    };

    return {
        init,
        destroy
    };

};
