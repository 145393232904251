import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../../core/Dom';
import agent from '../../core/request';
import Components from '../../core/Components';
import Dispatch from '../../core/Dispatch';
import { SEASONBOOK_TWO_STEP_MANUAL_SUBMIT } from '../../lib/events';

export default el => {
    const dom = $(el);
    const inputs = dom.find('input');
    const masterForm = dom.find('[data-master-form]');
    const masterFieldsets = masterForm.find('fieldset');
    const concertResultsWrapper = dom.find('[data-concerts-results]');

    const scrollToConcerts = () => {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: dom.position().top,
                autoKill: false
            },
            ease: 'power2.inOut'
        });
    };

    const beforeLoadConcerts = () => {
        concertResultsWrapper.css('height', concertResultsWrapper.height());
        concertResultsWrapper.empty();
    };

    const loadConcerts = url => {
        beforeLoadConcerts();
        const urlToPost = url || masterForm.attr('action');
        const params = serialize(masterForm.get(0));
        agent
            .post(urlToPost)
            .send(params)
            .end((error, res) => {
                if (!error && res.status === 200) {
                    const results = $('<div></div>').html(res.text).find('[data-concerts-results]').html();
                    concertResultsWrapper.empty().append(results);
                    Components.parse(concertResultsWrapper.get(0));
                    concertResultsWrapper.css('height', null);
                } else {
                    // error
                }
            });
    };

    const updateMasterForm = () => {
        masterFieldsets.each(node => {
            const fieldset = $(node);
            fieldset.find('input').each(input => {
                if (input.checked) {
                    $(input).parent().removeClass('!hidden').addClass('!flex');
                } else {
                    $(input).parent().removeClass('!flex').addClass('!hidden');
                }
            });
            const checked = fieldset.find('input:checked');
            if (checked.length) {
                fieldset.removeClass('hidden');
            } else {
                fieldset.addClass('hidden');
            }
        });
        if (masterForm.find('fieldset:not(.hidden)').length) {
            masterForm.removeClass('!hidden');
        } else {
            masterForm.addClass('!hidden');
        }
        loadConcerts();
    };

    const onUpdate = e => {
        const { name, value } = e.target;
        inputs.not(e.target).each(input => {
            if (input.name === name && input.value === value && input.name !== 'variant') {
                input.checked = e.target.checked;
            }
        });
        if ($(e.target).parent('[data-two-step-submit]').length === 0) {
            setTimeout(updateMasterForm, 50);
        }
    };

    const onTwoStepManualSubmit = () => {
        scrollToConcerts();
        updateMasterForm();
    };

    const onReset = () => {
        inputs.each(input => {
            input.checked = false;
        });
        setTimeout(updateMasterForm, 50);
    };

    const onPaginate = e => {
        e.preventDefault();
        scrollToConcerts();
        loadConcerts(e.target.href);
    };

    const init = () => {
        Dispatch.on(SEASONBOOK_TWO_STEP_MANUAL_SUBMIT, onTwoStepManualSubmit);
        inputs.on('change', onUpdate);
        masterForm.on('reset', onReset);
        dom.on('click', '[data-next]', onPaginate);
        dom.on('click', '[data-prev]', onPaginate);
    };

    const destroy = () => {
        Dispatch.off(SEASONBOOK_TWO_STEP_MANUAL_SUBMIT, onTwoStepManualSubmit);
        inputs.off('change', onUpdate);
        masterForm.off('reset', onReset);
    };

    return {
        init,
        destroy
    };
};
