import gsap from 'gsap';
import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';

export default (el, props) => {

    const dom = $(el);
    const image = dom.find('[data-image]').get(0);
    const contentLink = dom.find('[href="#content"]');
    const searchLink = dom.find('[href="#search"]');
    const searchField = $('input[data-search]').get(0);

    let timeline = false;
    let stageHeight = 0;

    const killTimeline = () => {
        if (timeline) {
            timeline.kill();
            timeline = false;
        }
    };

    const resetTimeline = () => {
        killTimeline();
        timeline = gsap.timeline({ paused: true, useFrames: true }).fromTo(image, {
            yPercent: 0
        }, {
            yPercent: ((stageHeight * 1.8) / 100),
            ease: 'none',
            duration: stageHeight
        });
    };

    const resize = () => {
        if (stageHeight !== Viewport.height) {
            stageHeight = Viewport.height;
            resetTimeline();
        }
    };

    const scroll = () => {
        const { scrollTop } = Viewport;
        if (scrollTop < stageHeight) {
            const percent = Math.max(0, Math.min(scrollTop / timeline.duration(), 1));
            timeline.progress(percent);
        }
    };

    const init = () => {
        searchLink.on('click', e => {
            e.preventDefault();
            searchField.focus();
        });
        contentLink.on('click', e => {
            e.preventDefault();
            const target = $('#content');
            if (target.length) {
                gsap.to(window, { duration: 1, scrollTo: { y: target.position().top, autoKill: false }, ease: 'power2.inOut' });
            }
        });
        Viewport.on('resize', resize);
        Viewport.on('scroll', scroll);
        resize();
        scroll();
    };

    const destroy = () => {
        killTimeline();
        searchLink.off('click');
        contentLink.off('click');
        Viewport.off('resize', resize);
        Viewport.off('scroll', scroll);
    };

    return {
        init,
        destroy
    };

};
