import $ from '../../core/Dom';

export default el => {
    const button = $(el).find('[data-button]');
    const wrapper = $(el).find('[data-wrapper]');
    const inputs = $(el).find('input[type="radio"]');
    let isOpen = button.attr('aria-expanded') === 'true';

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (key === 'Escape') {
            close();
        }
    };

    const onFocusIn = e => {
        if (!el.contains(e.target)) {
            close();
        }
    };

    const onEatFocusIn = e => {
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        button.attr('aria-expanded', 'true');
        document.body.addEventListener('keyup', onBodyKeyUp);
        document.body.addEventListener('focusin', onFocusIn);
    };

    const close = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        button.attr('aria-expanded', 'false');
        document.body.removeEventListener('keyup', onBodyKeyUp);
        document.body.removeEventListener('focusin', onFocusIn);
    };

    const toggle = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const onChange = e => {
        const target = $(e.target);
        const options = $(el).parent().find('[data-component="seasonbook/FindYourConcertOptions"]');
        button.find('span').text(target.data('title'));
        options.each(node => {
            const item = $(node);
            if (node.hasAttribute(`data-${target.val()}`)) {
                item.removeClass('hidden').addClass('block');
            } else {
                item.addClass('hidden').removeClass('block');
                item.find('input').each(radio => {
                    radio.checked = false;
                });
                const firstInput = item.find('input').first();
                item.find('[data-button] span').text(firstInput.data('title'));
            }
        });
        close();
    };

    const init = () => {
        button.on('click', toggle);
        wrapper.on('focusin', onEatFocusIn);
        inputs.on('change', onChange);
    };

    const destroy = () => {
        button.off('click', toggle);
        wrapper.off('focusin', onEatFocusIn);
        inputs.off('change', onChange);
        close();
    };

    return {
        init,
        destroy
    };

};
