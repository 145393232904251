import $ from '../../core/Dom';

export default el => {
    const button = $(el).find('[data-button]');
    const wrapper = $(el).find('[data-wrapper]');
    let isOpen = button.attr('aria-expanded') === 'true';

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (key === 'Escape') {
            close();
        }
    };

    const onFocusIn = e => {
        if (!el.contains(e.target)) {
            close();
        }
    };

    const onEatFocusIn = e => {
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        button.attr('aria-expanded', 'true');
        document.body.addEventListener('keyup', onBodyKeyUp);
        document.body.addEventListener('focusin', onFocusIn);
    };

    const close = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        button.attr('aria-expanded', 'false');
        document.body.removeEventListener('keyup', onBodyKeyUp);
        document.body.removeEventListener('focusin', onFocusIn);
    };

    const toggle = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        button.on('click', toggle);
        wrapper.on('focusin', onEatFocusIn);
    };

    const destroy = () => {
        button.off('click', toggle);
        wrapper.off('focusin', onEatFocusIn);
        close();
    };

    return {
        init,
        destroy
    };

};
