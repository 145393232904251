import $ from '../../core/Dom';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default el => {
    let flkty;
    const dom = $(el);
    const slides = dom.find('[data-slides]');

    const init = () => {
        loadFlickity(Flickity => {
            flkty = new Flickity(slides.get(0), {
                contain: true,
                dragThreshold: 10,
                cellAlign: 'left',
                cellSelector: '[data-slide]',
                prevNextButtons: false,
                pageDots: false,
                wrapAround: false,
                freeScroll: true,
                groupCells: true,
                freeScrollFriction: 0.045
            });

            flkty.on('dragStart', () => {
                document.ontouchmove = e => e.preventDefault();
            });

            flkty.on('dragEnd', () => {
                document.ontouchmove = () => true;
            });
            dom.removeClass('overflow-auto').addClass('overflow-hidden');
        });
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
    };

    return {
        init,
        destroy
    };
};
