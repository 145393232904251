import * as eventKeys from '../lib/events';
import Dispatch from '../core/Dispatch';

export default (el, props) => {

    const clickHandler = e => {
        e.preventDefault();
        Dispatch.emit(eventKeys.AUDIO_ADD, props);
    };

    const init = () => {
        el.addEventListener('click', clickHandler);
    };

    const destroy = () => {
        el.removeEventListener('click', clickHandler);
    };

    return {
        init,
        destroy
    };

};
