import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import * as Events from '../lib/events';
import agent from '../core/request';

export default el => {
    const dom = $(el);
    const close = dom.find('[data-close]');
    const wrapper = dom.find('[data-wrapper]');
    const form = dom.find('[data-form]').get(0);
    const receipt = dom.find('[data-receipt]').get(0);
    const tags = dom.find('[name="tags[]"]:not([value])');
    const box = wrapper.get(0);

    let active = false;
    let opener = null;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.REMINDER_CLOSE);
        }
    };

    const onOpen = () => {
        if (!active) {
            active = true;
            opener = document.activeElement;
            document.addEventListener('keyup', keyHandler);
            gsap.set(box, {opacity: 0, scale: 0.8});

            gsap.to(el, { duration: 0.35, autoAlpha: 1, ease: 'power1.inOut' });
            gsap.to(box, { duration: 0.35, delay: 0.25, opacity: 1, ease: 'sine.out' });
            gsap.to(box, { duration: 0.35, delay: 0.25, scale: 1, ease: 'power2.out' });

            setTimeout(() => {
                Viewport.lockTabbing(dom, close);
            }, 10);
        }
    };

    const onClose = () => {
        if (active) {
            active = false;
            document.removeEventListener('keyup', keyHandler);
            gsap.to(box, { duration: 0.5, opacity: 0, scale: 0.85, ease: 'power2.inOut' });
            gsap.to(el, {
                duration: 0.35,
                delay: 0.25,
                autoAlpha: 0,
                ease: 'power1.inOut',
                onComplete() {
                    form.reset();
                    gsap.set(receipt, {autoAlpha: 0});
                }
            });
            Viewport.releaseTabbing(opener);
        }
    };

    const onClick = e => {
        e.preventDefault();
        const target = $(e.triggerTarget);
        const data = target.data('reminder-button');
        tags.val(data.tags);
        Dispatch.emit(Events.REMINDER_OPEN);
    };

    const onSubmit = e => {
        e.preventDefault();
        const params = serialize(e.target);
        agent
            .post('/')
            .accept('application/json')
            .send(params)
            .end((error, res) => {
                const response = JSON.parse(res.text);
                console.log(response);
                if (error || !response.success) {
                    // error
                } else {
                    gsap.to(receipt, {
                        duration: 0.35,
                        autoAlpha: 1,
                        ease: 'power1.inOut',
                        onComplete() {
                            close.get(0).focus();
                        }
                    });
                }
            });
    };

    const init = () => {
        Dispatch.on(Events.REMINDER_OPEN, onOpen);
        Dispatch.on(Events.REMINDER_CLOSE, onClose);

        close.on('click', onClose);

        dom.on('click', e => {
            if (!wrapper.get(0).contains(e.target)) {
                Dispatch.emit(Events.REMINDER_CLOSE);
            }
        });

        $(form).on('submit', onSubmit);

        $('[data-reminder-button]').on('click', onClick);
    };

    const destroy = () => {
        Dispatch.off(Events.REMINDER_OPEN, onOpen);
        Dispatch.off(Events.REMINDER_CLOSE, onClose);
        dom.off('click');
        close.off('click');
        $(form).off('submit');
        $('[data-reminder-button]').off('click', onClick);
    };

    return {
        init,
        destroy
    };
};
