import $ from '../../core/Dom';
import agent from '../../core/request';

export default el => {

    const dom = $(el);
    let toggles = dom.find('[data-toggle]');
    let request = null;

    const swapToButtons = () => {
        toggles.nodes.forEach(node => {
            node.outerHTML = node.outerHTML.replace('<a', '<button').replace('</a>', '</button>');
        });
        toggles = dom.find('[data-toggle]').attr('href', null);
    };

    const onToggle = e => {
        console.log('AjaxAccordion.onToggle');
        e.preventDefault();
        const toggle = $(e.currentTarget);
        const accordion = toggle.parent('[data-item]');
        const wasLoading = accordion.hasClass('js-is-loading');

        // abort request if any
        if (request) {
            request.abort();
            request = null;
            // already loading so
            if (wasLoading) {
                return false;
            }
        }

        const content = accordion.find('[data-content]');
        const isExpanded = toggle.attr('aria-expanded') === 'true';

        if (isExpanded) {
            content.attr('hidden', '');
            toggle.attr('aria-expanded', 'false');
            accordion.removeClass('js-is-expanded');
        } else {
            if (content.data('loaded')) {
                content.attr('hidden', null);
                toggle.attr('aria-expanded', 'true');
                accordion.addClass('js-is-expanded');
            } else {
                accordion.addClass('js-is-loading');
                request = agent
                    .get(accordion.data('url'))
                    .end((error, res) => {
                        if (error || !res.text) {
                            console.log('error');
                        } else {
                            const loadedContent = $(res.text).find('[data-loaded-content]');
                            if (loadedContent.length) {
                                content.data('loaded', true);
                                content.append(loadedContent);
                                content.attr('hidden', null);
                                toggle.attr('aria-expanded', 'true');
                                accordion.addClass('js-is-expanded');
                                accordion.removeClass('js-is-loading');
                            }
                        }
                    });
            }
        }
    };

    const init = () => {
        if (toggles) {
            console.log('AjaxAccordion.init');
            swapToButtons();
            toggles.on('click', onToggle);
        }
    };

    const destroy = () => {
        toggles.off('click', onToggle);
    };

    return {
        init,
        destroy
    };

};
