import gsap from 'gsap';
import $ from '../../core/Dom';

export default (el, props) => {

    const button = $(el).find('[aria-expanded]');
    const content = $(el).find('[data-content]').get(0);
    const toggleIcon = button.find('svg').get(0);

    const textShow = button.attr('aria-label');
    const textHide = button.data('hide');

    let expanded = false;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (expanded && key === 27 && el.contains(document.activeElement)) {
            close();
        }
    };

    const open = () => {
        if (!expanded) {
            button.attr('aria-expanded', 'true').attr('aria-label', textHide);
            gsap.set(content, { height: 'auto' });
            gsap.from(content, {
                height: 0,
                duration: 0.55,
                ease: 'power2.inOut',
                onComplete: () => {
                    gsap.set(content, { height: 'auto' });
                }
            });
            gsap.to(toggleIcon, {
                duration: 0.55,
                ease: 'power2.inOut',
                scaleY: -1
            });
            expanded = true;
            document.addEventListener('keyup', keyHandler);
        }
    };

    const close = () => {
        if (expanded) {
            button.attr('aria-expanded', 'false').attr('aria-label', textShow);
            gsap.to(content, {
                height: 0,
                duration: 0.55,
                ease: 'power2.inOut',
                clearProps: 'height'
            });
            gsap.to(toggleIcon, {
                duration: 0.55,
                ease: 'power2.inOut',
                scaleY: 1
            });
            expanded = false;
            document.removeEventListener('keyup', keyHandler);
        }
    };

    const toggle = e => {
        e.preventDefault();
        if (expanded) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };

};
