import gsap from 'gsap';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';

export default (el, props) => {

    const dom = $(el);
    const logo = dom.find('[data-logo]').get(0);
    const slides = dom.find('[data-slide]');
    const slidesWithoutStart = slides.not('#start');
    const next = dom.find('[data-next]');
    const prev = dom.find('[data-prev]');
    const status = dom.find('[data-status]');
    let index = 0;
    let isSlider = false;

    const updateGui = () => {
        status.text(`${index + 1} / ${slides.length}`);
        prev.attr('disabled', index > 0 ? null : '');
        next.attr('disabled', index < (slides.length - 1) ? null : '');
    };

    const setIndex = value => {
        if ((value < 0 && index > 0) || (value > 0 && index < (slides.length - 1))) {
            const currentSlide = slides.eq(index);
            const nextSlide = slides.eq(index + value);
            const other = slides.not(currentSlide).not(nextSlide);
            index += value;

            if (index === 0) {
                gsap.to(logo, {
                    duration: 1,
                    scale: 1,
                    y: 0,
                    ease: 'power2.inOut'
                });
            } else {
                gsap.to(logo, {
                    duration: 0.9,
                    delay: 0.1,
                    scale: 0.357,
                    y: -20,
                    ease: 'power2.inOut'
                });
            }

            gsap.killTweensOf(slides.find('[data-slide-media], [data-slide-text]').nodes);
            gsap.set(other.find('[data-slide-text]').nodes, { autoAlpha: 0 });
            gsap.set(other.find('[data-slide-media]').nodes, { opacity: 0, scale: 1 });

            nextSlide.css('zIndex', 0);
            currentSlide.css('zIndex', 1);

            gsap.set(nextSlide.find('[data-slide-media]').nodes, { opacity: 1, scale: 1 });
            gsap.set(currentSlide.find('[data-slide-media]').nodes, { opacity: 1, scale: 1 });

            gsap.to(currentSlide.find('[data-slide-text]').nodes, {
                duration: 0.65,
                autoAlpha: 0,
                ease: 'power2.inOut'
            });

            gsap.to(nextSlide.find('[data-slide-text]').nodes, {
                duration: 0.65,
                delay: 0.65,
                autoAlpha: 1,
                ease: 'power2.inOut'
            });

            gsap.to(currentSlide.find('[data-slide-media]').nodes, {
                duration: 1,
                scale: 1.02,
                opacity: 0,
                ease: 'power2.out'
            });

            updateGui();
        }
    };

    const keyUp = e => {
        if (e.keyCode === 39) {
            setIndex(1);
        } else if (e.keyCode === 37) {
            setIndex(-1);
        }
    };

    const resetNav = () => {
        index = 0;
        if (isSlider) {
            window.scrollTo(0, 0);
            document.addEventListener('keyup', keyUp);
            prev.on('click', () => { setIndex(-1); });
            next.on('click', () => { setIndex(1); });
        } else {
            document.removeEventListener('keyup', keyUp);
            prev.off('click');
            next.off('click');
        }
        updateGui();
    };

    const resize = () => {
        if (isSlider && Viewport.width < Viewport.height) {
            isSlider = false;
            slidesWithoutStart.css('position', null);
            gsap.set(slides.find('[data-slide-text]').nodes, { autoAlpha: 1 });
            gsap.set(slides.find('[data-slide-media]').nodes, { opacity: 1, scale: 1 });
            gsap.set(logo, { scale: 1, y: 0 });
            resetNav();
        } else if (!isSlider && Viewport.width > Viewport.height) {
            isSlider = true;
            slidesWithoutStart.css('position', 'absolute');
            gsap.set(slidesWithoutStart.find('[data-slide-text]').nodes, { autoAlpha: 0 });
            gsap.set(slidesWithoutStart.find('[data-slide-media]').nodes, { opacity: 0, scale: 1 });
            resetNav();
        }
    };

    const init = () => {
        Viewport.on('resize', resize);
        resize();
    };

    const destroy = () => {
        Viewport.off('resize', resize);
    };

    return {
        init,
        destroy
    };

};
