import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const button = $(el).find('[aria-expanded]');
    const list = $(el).find('[data-list]').get(0);
    const hiddenItems = $(el).find('li.hidden');
    const duration = 0.4 + hiddenItems.length * 0.05;
    let expanded = false;

    const getCollapsedHeight = () => {
        hiddenItems.addClass('hidden');
        const height = list.offsetHeight;
        hiddenItems.removeClass('hidden');
        return height;
    };

    const open = () => {
        if (!expanded) {
            expanded = true;
            button.attr('aria-expanded', 'true').text(button.data('hide'));
            // hiddenItems.removeClass('hidden');
            gsap.from(list, {
                height: getCollapsedHeight(),
                duration,
                ease: 'power2.inOut',
                onComplete: () => {
                    gsap.set(list, { height: 'auto' });
                }
            });
        }
    };

    const close = () => {
        if (expanded) {
            expanded = false;
            button.attr('aria-expanded', 'false').text(button.data('show'));
            gsap.to(list, {
                height: getCollapsedHeight(),
                duration,
                ease: 'power2.inOut',
                onComplete: () => {
                    hiddenItems.addClass('hidden');
                    gsap.set(list, { height: 'auto' });
                }
            });
        }
    };

    const toggle = () => {
        if (expanded) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
