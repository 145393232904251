import gsap from 'gsap';
import { Quart, Sine, Quint, Circ, Expo } from 'gsap/gsap-core';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {
    const dom = $(el);
    const body = $('body');
    const header = dom.find('[data-header]');
    const menu = dom.find('[data-menu]').get(0);
    const search = dom.find('[data-search]').get(0);
    const hamburger = dom.find('[data-menu-toggle]');
    const hamburgerTop = hamburger.find('i:nth-of-type(1)').get(0);
    const hamburgerMiddle = hamburger.find('i:nth-of-type(2)').get(0);
    const hamburgerBottom = hamburger.find('i:nth-of-type(3)').get(0);

    const killAnimItems = [menu];

    let menuOpen = false;
    let scrollbarWidth = -1;

    const getFocusableElements = () => [
        dom.find('[data-menu]').get(0),
        hamburger.get(0),
        search
    ];

    const getScrollbarWidth = () => {
        if (scrollbarWidth === -1) {
            const initialBodyWidth = body.width();
            body.css('overflow', 'hidden');
            const fullBodyWidth = body.width();
            body.css('overflow', '');
            scrollbarWidth = fullBodyWidth - initialBodyWidth;
        }
        return scrollbarWidth;
    };

    const hamburgerToX = () => {
        const offset = 8;
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            y: offset * -1,
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 0 });
                gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', scaleX: 0.7, ease: Quart.easeOut });
                gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', scaleX: 0.7, ease: Quart.easeOut });
            }
        });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.15, rotationZ: '0deg', ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            rotationZ: '0deg',
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.1, y: 0, scaleX: 1, ease: Quart.easeOut, clearProps: 'all' });
                gsap.to(hamburgerBottom, { duration: 0.1, y: 0, scaleX: 1, ease: Quart.easeOut, clearProps: 'all' });
            }
        });
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggleMenu();
        }
    };

    const openMenu = () => {
        body.on('keyup', onBodyKeyUp).addClass('menu-open');
        gsap.killTweensOf(killAnimItems);
        hamburgerToX();
        Viewport.lockTabbing(getFocusableElements());
        gsap.to(menu, { duration: 0.5, autoAlpha: 1, ease: Quint.easeInOut, onComplete: () => {
            Viewport.lockScrolling();
            header.css('padding-right', `${getScrollbarWidth()}px`);
            $(menu).css('overflow', 'auto');
        }});
    };

    const closeMenu = () => {
        body.off('keyup', onBodyKeyUp).removeClass('menu-open');
        gsap.killTweensOf(killAnimItems);
        gsap.to(menu, { duration: 0.5, autoAlpha: 0, ease: Quint.easeInOut });
        xToHamburger();
        Viewport.releaseScrolling();
        Viewport.releaseTabbing(hamburger.get(0));
        $(menu).css('overflow', null);
        header.css('padding-right', null);
    };

    const toggleMenu = e => {
        if (e) {
            e.preventDefault();
        }
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
        menuOpen = !menuOpen;
    };

    const init = () => {
        hamburger.on('click', toggleMenu);
        getScrollbarWidth();
    };

    const destroy = () => {
        hamburger.off('click', toggleMenu);
    };

    return {
        init,
        destroy
    };
};
