import gsap from 'gsap';
import $ from '../core/Dom';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default el => {

    const dom = $(el);
    const boxes = dom.find('[data-textbox]');

    let triggers = [];

    const destroyScrollTriggers = () => {
        triggers.forEach(trigger => trigger.kill());
        triggers = [];
    };

    const destroy = () => {
        destroyScrollTriggers();
    };

    const getMediaElement = node => {
        const previousElement = node.parentNode.parentNode.previousElementSibling;
        return previousElement.nodeName === 'FIGURE' && previousElement.getAttribute('data-first') === null ? previousElement : null;
    };

    const setup = ScrollTrigger => {
        const bottomTrigger = ScrollTrigger.create({
            trigger: el,
            start: 'top top',
            end: 'bottom bottom',
            onEnterBack() {
                dom.removeClass('js-at-bottom');
            },
            onLeave() {
                dom.addClass('js-at-bottom');
            }
        });
        triggers.push(bottomTrigger);
        boxes.each(box => {
            const trigger = ScrollTrigger.create({
                trigger: box,
                start: 'top bottom-=100px',
                end: 'top top-=100px',
                onEnter() {
                    const media = getMediaElement(box);
                    if (media) {
                        const wrapper = media.firstElementChild;
                        const video = media.querySelector('video');
                        if (video) {
                            video['instance'].play();
                        }
                        gsap.killTweensOf([media,wrapper]);
                        gsap.set(wrapper, { scale: 1.1 });
                        gsap.to(wrapper, { duration: 3, scale:1, ease: 'expo.out' });
                        gsap.to(media, { duration: 1.5, autoAlpha: 1, ease: 'power2.inOut' });
                    }
                },
                onLeaveBack() {
                    const media = getMediaElement(box);
                    if (media) {
                        const wrapper = media.firstElementChild;
                        const video = media.querySelector('video');
                        if (video) {
                            video['instance'].pause();
                        }
                        gsap.killTweensOf([media,wrapper]);
                        gsap.to(media, { duration: 1.5, autoAlpha: 0, ease: 'power2.inOut' });
                    }
                }
            });
            triggers.push(trigger);
        });
    };

    const init = () => {
        loadScrollTrigger(module => {
            console.log(module.ScrollTrigger);
            gsap.registerPlugin(module.ScrollTrigger);
            setup(module.ScrollTrigger);
        });
    };

    return {
        init,
        destroy
    };

};
